<template>
  <v-row class="ma-0" justify="center">
    <!-- news dialog -->
    <v-dialog v-model="showDialog" @click:outside="closeHandler"
              @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
              scrollable max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn @click="showDialog = true" color="main" class="white--text" small depressed v-on="on">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <v-card class="pa-1">
        <!-- title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations.newsNew }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- title -->
          <v-col class="pa-0 mt-4">
            <b class="ml-8">{{ translations.title }}:</b>
            <v-text-field ref="title" v-model="title" prepend-icon="mdi-text" class="mt-1" outlined hide-details dense></v-text-field>
          </v-col>

          <!-- image -->
          <v-col class="pa-0 mt-8">
            <b class="ml-8">{{ translations.image }}:</b>
            <v-file-input v-model="image" accept="image/*" prepend-icon="mdi-camera" class="pt-0" hide-details></v-file-input>
            <v-img :src="imageDisplay" v-if="imageDisplay" :width="100" max-width="100" class="ml-8 mt-2" cover></v-img>
          </v-col>

          <!-- description -->
          <v-col class="pa-0 mt-8">
            <b class="ml-8">{{ translations.description }}:</b>
            <v-textarea v-model="description" prepend-icon="mdi-text-long" class="mt-1" filled flat hide-details dense></v-textarea>
          </v-col>

          <!-- files -->
          <v-col class="pa-0 mt-8">
            <b class="ml-8">{{ translations.attachments }}:</b>
            <v-file-input v-model="files" prepend-icon="mdi-attachment" class="pt-0" hide-details multiple @change="handleFileChange"></v-file-input>
            <v-row class="ml-8 mt-2">
              <v-chip class="ma-1" v-for="(fileName, index) in fileNames" :key="index" close @click:close="removeAttachment(index)">
                {{ fileName }}
              </v-chip>
            </v-row>
          </v-col>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="center">
            <v-btn @click="saveNews" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.create }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.generalError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'
import { Rules } from '@/utils/mixins/Rules'

import NewsService from '@/service/news/NewsService'

import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'NewsNew',

  components: {
    MessageDialog,
    WarningSvg
  },

  data() {
    return {
      translations: translations,
      title: '',
      description: '',
      image: null,
      files: [],
      filesInfo: [],
      fileNames: [],

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      page: 1
    }
  },

  computed: {
    imageDisplay() {
      if (!this.image) return null
      return URL.createObjectURL(this.image)
    },

    disableBtn() {
      return !(
        this.validRequired(this.title)
        && this.validRequired(this.description)
      )
    }
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  methods: {
    async saveNews() {
      this.loading = true
      const filesInfo = []

      // Convert the image to Base64 and mark it as news image only if image is present
      if (this.image) {
        const imageInfo = await this.convertSingleFileToBase64(this.image, true)
        filesInfo.push(imageInfo)
      }

      // Convert the attachments to Base64 only if attachments are present
      if (this.files.length > 0) {
        const attachmentsInfo = await this.convertFilesToBase64(this.files, false)
        filesInfo.push(...attachmentsInfo)
      }

      await NewsService.save({
          newsDTO: {
            title: this.title,
            description: this.description,
            files: filesInfo.length > 0 ? filesInfo : null
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.showDialog = false
          this.closeHandler()
        })
    },

    async convertFilesToBase64(files, isNewsImage) {
      const promises = files.map(file => this.convertSingleFileToBase64(file, isNewsImage))
      return await Promise.all(promises)
    },

    async convertSingleFileToBase64(file, isNewsImage) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64String = reader.result.split(',')[1]
          const fileType = file.name.split('.').pop()
          resolve({ base64: base64String, fileType, fileName: file.name, isNewsImage })
        }
        reader.onerror = error => reject(error)
      })
    },

    handleFileChange() {
      if (this.files.length > 0) {
        this.fileNames = this.files.map(file => file.name)
      }
    },

    removeAttachment(index) {
      this.files.splice(index, 1)
      this.fileNames.splice(index, 1)
    },

    closeHandler() {
      this.title = ''
      this.description = ''
      this.image = null
      this.files = []
      this.fileNames = []
    }
  }
}
</script>
