<template>
  <v-row class="ma-0" justify="center">
    <!-- loading -->
    <v-progress-circular v-if="loading" :size="15" color="main" indeterminate />

    <template v-else>
      <!-- edit activity dialog -->
      <v-dialog v-model="showDialog" @click:outside="closeHandler" @keydown="value => (value.key === 'Escape' ? closeHandler() : '')" scrollable max-width="700px">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-2" @click="showDialog = true" v-on="on">mdi-square-edit-outline</v-icon>
        </template>

        <v-card class="pa-1">
          <!-- card title -->
          <v-card-title class="d-flex justify-center">
            <h3 class="main--text">{{ translations.editNews }}</h3>
          </v-card-title>

          <v-card-text>
            <!-- tabs start -->
            <v-tabs>
              <v-tab key="edit">{{ translations.edit }}</v-tab>
              <v-tab key="details">{{ translations.details }}</v-tab>

              <!-- tab: edit -->
              <v-tab-item key="edit">
                <v-col cols="12">
                  <!-- title -->
                  <v-col class="pa-0 mt-4">
                    <b class="ml-8">{{ translations.title }}:</b>
                    <v-text-field ref="title" v-model="title" prepend-icon="mdi-text" class="mt-1" outlined hide-details dense></v-text-field>
                  </v-col>

                  <!-- image -->
                  <v-col class="pa-0 mt-8">
                    <b class="ml-8">{{ translations.image }}:</b>
                    <v-file-input :key="imageInputKey" accept="image/*" prepend-icon="mdi-camera" class="pt-0" hide-details @change="handleImageChange" :placeholder="imageInputLabel"></v-file-input>
                    <div v-if="newsImage" class="d-flex">
                      <v-img :src="newsImage && newsImage.base64 ? `data:${newsImage.fileType};base64,${newsImage.base64}` : ''" :width="100" max-width="100" class="ml-8 mt-2" cover></v-img>
                      <v-btn icon small class="ma-1" @click="removeImage">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <!-- date -->
                  <v-col class="pa-0 mt-8">
                    <b class="ml-8">{{ translations.lastUpdateAt }}:</b>
                    <v-text-field v-model="formattedInstantDate" prepend-icon="mdi-calendar" class="pt-0" disabled></v-text-field>
                  </v-col>

                  <!-- description -->
                  <v-col class="pa-0 mt-8">
                    <b class="ml-8">{{ translations.description }}:</b>
                    <v-textarea v-model="description" prepend-icon="mdi-text-long" class="mt-1" filled flat hide-details dense></v-textarea>
                  </v-col>

                  <!-- files -->
                  <v-col class="pa-0 mt-8">
                    <b class="ml-8">{{ translations.attachments }}:</b>
                    <v-file-input :key="filesInputKey" :placeholder="fileInputLabel" prepend-icon="mdi-attachment" class="pt-0" hide-details multiple @change="handleFileChange"></v-file-input>
                    <v-row class="ml-8 mt-2">
                      <v-chip class="ma-1" v-for="(fileName, index) in fileNames" :key="index" @click="handleDownload(fileName)" close @click:close="() => removeAttachment(index)">
                        {{ fileName }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-col>
              </v-tab-item>

              <!-- tab: details -->
              <v-tab-item key="details">
                <v-row>
                  <!-- read By -->
                  <v-col cols="6">
                    <div class="pa-0 mt-6 border-left pl-2">
                      <h5 class="list-header">{{ translations.readBy }}: ({{ readEmployees.length }})</h5>
                      <div class="scroll-bar" style="max-height: 200px; overflow-y: auto;">
                        <v-list>
                          <v-list-item v-for="employee in readEmployees" :key="employee.id">
                            <v-list-item-content>
                              <v-list-item-title>{{ employee.employee.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-col>

                  <!-- unread By -->
                  <v-col cols="6">
                    <div class="pa-0 mt-6 border-left pl-2">
                      <h5 class="list-header">{{ translations.notReadBy }}: ({{ unreadEmployees.length }})</h5>
                      <div class="scroll-bar" style="max-height: 200px; overflow-y: auto;">
                        <v-list>
                          <v-list-item v-for="employee in unreadEmployees" :key="employee.id">
                            <v-list-item-content>
                              <v-list-item-title>{{ employee.employee.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card-text>

          <!-- modify action -->
          <v-card-actions class="pb-4 pt-4">
            <v-row class="ma-0" justify="space-around">
              <v-btn @click="closeDialog" class="px-8" outlined :loading="loading">{{ translations.close }}</v-btn>

              <v-btn @click="updateNews" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.edit }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- delete -->
      <v-icon @click="deleteNews" small color="error lighten-1" class="cursor-pointer">mdi-delete-outline</v-icon>

      <!-- message dialog -->
      <message-dialog :show="showErrorMsgDialog">
        <template v-slot:card-image>
          <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
            <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
          </div>
        </template>

        <template v-slot:message>
          <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
            {{ translations.generalError }}
          </div>
        </template>

        <template v-slot:card-action>
          <div class="d-flex justify-center cursor-pointer" style="width: 100%">
            <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
          </div>
        </template>
      </message-dialog>
    </template>
  </v-row>
</template>

<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'
import { Rules } from '@/utils/mixins/Rules'

import NewsService from '@/service/news/NewsService'

import MessageDialog from '@/components/MessageDialog.vue'
import WarningSvg from '@/assets/WarningSvg.vue'

export default {
  name: 'NewsEdit',

  components: {
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      title: this.item.title,
      description: this.item.description,
      instant: this.item.instant,
      newsImage: null,
      uploadedFiles: [],
      filesInfo: [],
      fileNames: [],
      filesInputKey: null,
      imageInputKey: null
    }
  },

  created() {
    this.initializeFiles()
  },

  computed: {
    readEmployees() {
      if (this.item && this.item.newsEmployeeList) {
        return this.item.newsEmployeeList.filter(emp => emp.read)
      }
      return []
    },

    unreadEmployees() {
      if (this.item && this.item.newsEmployeeList) {
        return this.item.newsEmployeeList.filter(emp => !emp.read)
      }
      return []
    },

    formattedInstantDate() {
      return this.momentDate(this.item.instant, 'YYYY-MM-DD HH:mm')
    },

    fileInputLabel() {
      const len = this.fileNames.length
      if (len === 0) {
        return `${this.translations.attachments} (${translations.noFiles})`
      }
      if (len === 1) {
        return `${this.translations.attachment} (${this.fileNames[0]})`
      }
      return `${this.translations.attachments} (${len} ${translations.files})`
    },

    imageInputLabel() {
      return this.newsImage ? this.newsImage.fileName : translations.image
    },

    disableBtn() {
      return !(
        this.validRequired(this.title)
        && this.validRequired(this.description)
      )
    }
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  methods: {
    initializeFiles() {
      const nonImageFiles = this.item.files.filter(file => !file.isNewsImage)
      this.fileNames = nonImageFiles.map(file => file.fileName)

      const imageFile = this.item.files.find(file => file.isNewsImage)
      if (imageFile) {
        this.newsImage = {
          base64: imageFile.base64,
          fileType: imageFile.fileType,
          fileName: imageFile.fileName,
          isNewsImage: imageFile.isNewsImage
        }
      }
    },

    async updateNews() {
      this.loading = true

      const filesInfo = []

      // Add the image into files
      if (this.newsImage) {
        filesInfo.push(this.newsImage)
      }

      // Convert the uploadedFiles to Base64 only if uploadedFiles are present
      if (this.uploadedFiles.length > 0) {
        const attachmentsInfo = await this.convertFilesToBase64(this.uploadedFiles, false)
        filesInfo.push(...attachmentsInfo)
      }

      // Create a Set containing all the file names from uploadedFiles for quick lookup
      const uploadedFileNames = new Set(this.uploadedFiles.map(file => file.name))

      // Check if each fileName exists in uploadedFiles
      this.fileNames.forEach(fileName => {
        if (!uploadedFileNames.has(fileName)) {
          // Create an object for the missing file and push it to filesInfo
          const missingFile = {
            base64: '',
            fileName: fileName,
            fileType: '',
            isNewsImage: false
          }
          filesInfo.push(missingFile)
        }
      })

      await NewsService.save({
          newsDTO: {
            id: this.item.id,
            title: this.title,
            description: this.description,
            files: filesInfo.length > 0 ? filesInfo : null
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.showDialog = false
        })
    },

    closeDialog() {
      this.showDialog = false
    },

    async deleteNews() {
      this.loading = true
      await NewsService.delete({
          newsId: this.item.id
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$emit('updated')
        })
        .finally(() => {
          this.loading = false
        })
    },

    async handleDownload(fileName) {
      // Check if the file exists in this.uploadedFiles
      const fileToDownload = this.uploadedFiles.find(file => file.name === fileName)

      if (fileToDownload) {
        // File exists in uploadedFiles, download it directly
        const url = URL.createObjectURL(fileToDownload)
        this.downloadFile(url, fileToDownload.name)
        return
      }

      // If file does not exist in uploadedFiles, proceed to download from API
      const params = {
        newsId: this.item.id,
        fileName: fileName
      }

      try {
        const response = await NewsService.download(params)
        if (response && response.base64) {
          const blob = this.base64ToBlob(response.base64, response.fileType)

          const url = URL.createObjectURL(blob)

          this.downloadFile(url, response.fileName)
        } else {
          console.error('Failed to download file')
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },

    base64ToBlob(base64, mimeType) {
      const bytes = atob(base64)
      const len = bytes.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)

      for (let i = 0; i < len; i++) {
        view[i] = bytes.charCodeAt(i)
      }

      return new Blob([buffer], { type: mimeType })
    },

    downloadFile(url, fileName) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },

    removeAttachment(index) {
      // Remove the fileName
      const fileNameToRemove = this.fileNames[index]
      this.fileNames = this.fileNames.filter(name => name !== fileNameToRemove)

      // Remove the corresponding file from uploadedFiles
      this.uploadedFiles = this.uploadedFiles.filter(file => file.name !== fileNameToRemove)
    },

    removeImage() {
      this.newsImage = null
    },

    async handleImageChange(event) {
      await this.convertSingleFileToBase64(event, true).then(image => {
        this.newsImage = image
      })
      this.imageInputKey++
    },

    handleFileChange(event) {
      const newFileNames = []
      const newUploadedFiles = []

      for (let i = 0; i < event.length; i++) {
        const file = event[i]

        // Check for duplicate filenames
        if (this.fileNames.includes(file.name)) {
          console.error(`The file ${file.name} is already added.`)
          continue
        }

        newFileNames.push(file.name)
        newUploadedFiles.push(file)
      }

      // Update fileNames
      this.fileNames = [...this.fileNames, ...newFileNames]

      // Update uploadedFiles
      this.uploadedFiles = [...this.uploadedFiles, ...newUploadedFiles]

      this.filesInputKey++
    },

    async convertFilesToBase64(files, isNewsImage) {
      const promises = files.map(file => this.convertSingleFileToBase64(file, isNewsImage))
      return await Promise.all(promises)
    },

    async convertSingleFileToBase64(file, isNewsImage) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64String = reader.result.split(',')[1]
          const fileType = file.name.split('.').pop()
          resolve({ base64: base64String, fileType, fileName: file.name, isNewsImage })
        }
        reader.onerror = error => reject(error)
      })
    },

    closeHandler() {
      this.title = this.item.title
      this.description = this.item.description
      this.initializeFiles()
    }
  }
}
</script>
